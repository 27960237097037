export const fengTheme = {
    name: 'feng',
    properties: {
        '--background': '#fff',
        '--on-background': '#000',
        '--primary': '#B11116',
        '--primary-RGB': '177, 17, 22',
        '--on-primary': '#fff',
        '--secondary': '#BF9240',
        '--secondary-RGB': '191, 146, 64',
        '--on-secondary': '#fff',
        '--surface': '#fff',
        '--surface-RGB': '255, 255, 255',
        '--on-surface': '#000',
        '--error': '#F25455',
        '--error-RGB': '242, 84, 85',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--warning-RGB': '239, 200, 69',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--success-RGB': '104, 219, 108',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--info-RGB': '208, 208, 208',
        '--on-info': '#fff',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'D-DIN',
        '--font-title': 'IntegralCF',
        '--font-button': 'D-DIN',

        // Menu
        '--menu-itens-color': '#404040',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'black',
        '--menu-active-background': 'transparent',
        '--menu-active-underline': '#A11116',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'url("https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg_header+1.png") center bottom repeat-x',
        '--header-mobile-logo-width': '65px',
        '--header-desktop-logo-width': '89px',
        '--header-mobile-height': '130px',
        '--header-desktop-height': '220px',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '10px',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',

        // Home
        // Call to Action
        '--home-call-to-action-background': 'no-repeat center center / contain',
        '--home-call-to-action-background-img': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/banner-blackfriday-23/bg-blackfriday-23.png)',
        '--home-call-to-action-detail-color': '#fff',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',
        '--home-call-to-action-title-margin': '60px 0 50px',
        '--home-call-to-action-title-font-size': '20px',
        '--home-call-to-action-font-family': 'IntegralCF',
        '--home-call-to-action-content-align': 'left',
        '--home-call-to-action-content-padding': '130px 0 120px',
        '--home-call-to-action-button-background': 'var(--on-surface)',
        '--home-call-to-action-button-color': 'var(--background)',
        '--home-call-to-action-button-padding': '12px 30px',
        '--home-call-to-action-button-font-size': '15px',
        '--home-call-to-action-button-style': 'italic',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-transparencia.png)',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-perto+de+voce.png)',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-mais+novidades.png)',

        // First Access
        '--first-access-bg': '#fff',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '158px',

        // Plans
        '--plans-background': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-planos.png)',
        '--my-plan-font-family-title': 'Roboto',

        // Contact
        '--contact-background': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-contact.png) no-repeat center center / cover',
        '--panel-header-title-color': '#000',

        // Experiences
        '--experience-how-to-score-icon-filter': 'invert(7%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(135%)',

        // Embassies
        '--embassies-table-image-size': '60px',
        '--embassies-title-color': '#B11116',
        '--embassies-divider-color': '#E4E4E4',
        '--embassies-text-color': '#393939',

        //Landing
        '--landing-page-title-register-color':'#000',
        '--header-bottom-landing': '#fff',
        '--landing-page-button-register-color': '#D10B11',
        '--header-bg-landing': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/landing/header-vermelho.png) center bottom repeat-x',
        
        // X-Ray 
        '--x-ray-call-to-action-background': 'no-repeat center center / cover',
        '--x-ray-call-to-action-background-img': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/institucional.png)',
        '--x-ray-tab-header-top-position': '30px',
        '--x-ray-tab-header-ink-bar-height': '3px',

        '--event-background-select': 'rgba(255, 255, 255, 0.5)',
        '--event-background-table': '#f8f8f8',
        '--event-color-button': '#B11116',
        '--event-color-text-default': '#f8f8f8',
        '--event-color-text-button': '#fff',
        '--event-color-text-light': '#f8f8f8',
        '--event-color-text-dark': '#313131',
        '--event-color-text-primary': '#545452',
        '--event-color-text-secondary': '#bf9240',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': '#000',
        '--footer-background': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-footer.png) no-repeat center center / cover',
        '--footer-logo-width': '220px',

        // Auth Modal
        '--auth-modal-stlogo-width': '90px',
        '--auth-modal-stlogo-width-mobile': '70px',

        // Gift Card
        '--giftcard-primary-color': '#B11116',

        // Rating
        '--rating-bg-color': '#FAFAFA',
        '--rating-border-color': '#E4E4E4',
    },
    images: {
        'stLogo': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/spfc-st-logo.png',
        'stLogoMobile': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/spfc-st-logo.png',
        'stLogoFooter': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/logo-st-spcf-extenso-w.png',
        'loadingLogo': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/spfc-st-logo-white.png',
        'stShield': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/club-shield.svg',
        '--homePlansBG': 'url(https://spfc-gs.s3.amazonaws.com/prod/assets/images/bg-planos.png)',
        '--homeTicketsBG': 'url(https://flamengo.s3-sa-east-1.amazonaws.com/prod/assets/images/st-tickets-stadium-with-bg.jpg)',
        '--homeExclusiveContentBG': 'url(https://flamengo.s3-sa-east-1.amazonaws.com/prod/assets/images/grounge_stripe_bg_black.png)',
        'homeCallToActionImgMobile': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/Banner-Mobile-Deslogada.png',
        'homeCallToActionImgMedium': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/Banner-Mid-Deslogada.png',
        'homeCallToActionImgDesktop': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/Banner-Desktop-Deslogada.png',
    },
    texts: {
        // Home
        // Call to Action
        'callToActionFeatured': null,
        'callToActionFeaturedImg': 'https://spfc-gs.s3.amazonaws.com/prod/assets/images/banner-blackfriday-23/blackfriday-logo.png',
        'callToActionDetail': 'GARANTA SEU LUGAR JUNTO À TORCIDA QUE CONDUZ O TIME,<br>CONCORRA A EXPERIÊNCIAS INESQUECÍVEIS,<br>TENHA ACESSO A VANTAGENS NA COMPRA DE INGRESSOS,<br>NA REDE DE PARCEIROS E MUITO MAIS. <span class="clr">///</span>',
        'callToActionButton': 'CLIQUE AQUI E APROVEITE',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso sistema. Recomendamos, para a sua segurança, que confirme os dados abaixo e leia o novo Termo de Uso do programa.',

        'firstAccessTextChange': `
            <strong>AVISO LEGAL</strong>
            <br><br>
            O São Paulo Futebol Clube ("SPFC") está adequado e em conformidade com a Lei Geral de Proteção de Dados ("LGPD"), de modo que, os dados pessoais fornecidos por você, serão regularmente tratados de forma transparente, segura e confidencial e conforme a nossa <a href="/aviso-de-privacidade" target="_blank">Políticas de privacidade</a> e com o <a href="/termos-e-politicas" target="_blank">Regulamento do Programa de Sócio Torcedor do SPFC</a>, com os quais você desde já aceita e concorda. 
            <br><br>
            Ao preencher este formulário de cadastro, você concorda em aderir ao Programa de Relacionamento e Benefícios Sócio Torcedor, concebido e administrado pelo SPFC e seus parceiros, assim como concorda com o tratamento dos seus dados pessoais pelo SPFC e seus parceiros para a execução do Programa.
        `
    }
}
