import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { fengEnv } from './env';

if (fengEnv.production) {
    enableProdMode();
}

// Sentry.init({
//     environment: "development",
//     dsn: "https://2ce6ea776c9c43f69214150ea5468fc4@o1216838.ingest.sentry.io/6362367",
//     integrations: [
//         new BrowserTracing({
//             tracingOrigins: ["localhost", "https://api.sociotorcedor.com.br"],
//             routingInstrumentation: Sentry.routingInstrumentation,
//         }),
//     ],
//     tracesSampleRate: 1.0,
// });

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));