export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.stg.sociotorcedor.com.br/',
  // apiURL: 'http://localhost:3333/',
  weURL: 'https://admin.stg.sociotorcedor.com.br/WE',
  recaptcha: {
    key: '6Ld1uVEbAAAAAOB-rexb2_LQg0xvBODZfo0AqJhj',
  },
  auth: {
    new: true,
    social: [
      { name: 'google', provider: '854729594288-5lfd22d154ei8i28avlrjup13k0gk9p1.apps.googleusercontent.com' },
      { name: 'facebook', provider: '232919751041406' }
    ],
  },
};
