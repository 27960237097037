import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import {
  AppService,
  AuthAPIService,
  GoogleAnalyticsService,
} from '@fengbrasil/ngx-fengst-auth';
import { LoadingService, ResizeService } from '@fengbrasil/ngx-fengst-ui';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private subsink = new SubSink();

  public isHome: Observable<boolean> = this.appService.isHome$;
  public isDependent: Observable<boolean> = this.appService.isDependent$;
  public isAuth: Observable<boolean> = this.authService.isAuth$['st'];
  public isResale: Observable<boolean> = this.authService.isAuth$['resale'];
  public isLoading: Observable<boolean> = this.loadingService.isLoading$;
  title = 'ngx-feng';

  constructor(
    private resizeService: ResizeService,
    private loadingService: LoadingService,
    private authService: AuthAPIService,
    private appService: AppService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject('fengEnv') public fengEnv: any
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth;
    this.resizeService.setSize(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.resizeService.setSize(window.innerWidth);

    this.subsink.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: any) => {
          window.scrollTo(0, 0);
          this.appService.mainChecks(event.url);
        })
    );
  }

  onRouterOutletActivate(_event: any) {
    this.authService.getIdpessoa().then((idpessoa: string | null) => {
      // https://metricasboss.notion.site/Guia-de-implementa-o-da-camada-de-dados-54e3f6df0f5a4c5494b84fec535f2841
      const data: Record<string, unknown> = {
        event: 'virtual_page_view',
      };

      if (idpessoa) data.user_id = idpessoa;

      this.googleAnalyticsService.pushDataLayer(data);
    });
  }
}
