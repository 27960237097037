import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localePt);

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Theme & Settings
import { fengEnv } from '../env';
import { fengSettings } from '../settings';
import { fengTheme } from '../theme';

// FengST
import {
  JwtInterceptor,
  NgxFengSTAuthModule,
} from '@fengbrasil/ngx-fengst-auth';
import { NgxFengSTLayoutModule } from '@fengbrasil/ngx-fengst-layout';
import { NgxFengSTUiModule } from '@fengbrasil/ngx-fengst-ui';
// import { NgxFengSTAccountModule } from '@fengbrasil/ngx-fengst-account';
import { NgxFengSTCheckoutModule } from '@fengbrasil/ngx-fengst-checkout';
import { NgxFengSTContactModule } from '@fengbrasil/ngx-fengst-contact';
import { NgxFengstEmbassiesModule } from '@fengbrasil/ngx-fengst-embassies';
import { NgxFengstEventsModule } from '@fengbrasil/ngx-fengst-events';
import { NgxFengstExclusiveContentModule } from '@fengbrasil/ngx-fengst-exclusive-content';
import { NgxFengSTExperienceModule } from '@fengbrasil/ngx-fengst-experience';
import { NgxFengstLandingModule } from '@fengbrasil/ngx-fengst-landing';
import { NgxFengSTPartnerModule } from '@fengbrasil/ngx-fengst-partner';
import { NgxFengstSecurityModule } from '@fengbrasil/ngx-fengst-security';
import { NgxFengSTXRayModule } from '@fengbrasil/ngx-fengst-xray';
import { EmbedFormsComponent } from './embed-forms/embed-forms.component';

@NgModule({
  declarations: [AppComponent, EmbedFormsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,

    NgxFengSTUiModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTAuthModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTLayoutModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    // NgxFengSTAccountModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTCheckoutModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengstEventsModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengstExclusiveContentModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengstLandingModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTContactModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTPartnerModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTExperienceModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengSTXRayModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengstEmbassiesModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
    NgxFengstSecurityModule.forRoot({
      theme: fengTheme,
      settings: fengSettings,
      env: fengEnv,
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: fengEnv.recaptcha.key,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: true,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
